import React, {createContext, useContext, useEffect, useState} from "react";
import UserContext from "./UserContext";
import axios from "../../api/axios";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";

const WebsiteAdminContext = createContext()

export function WebsiteAdminProvider({ children }) {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const [init, setInit] = useState(true)
    const [loaded, setLoaded] = useState(false)
    const [currentWebsiteIndex, setCurrentWebsiteIndex] = useState(0)
    const [currentCollectionIndex, setCurrentCollectionIndex] = useState(0)
    const [websites, setWebsites] = useState([])

    useEffect(() => {
        if (init && user.isLoggedIn) {
            let websiteAdminSwal = Swal
            websiteAdminSwal.fire({
                title: t('WebsiteAdminContext.loading'),
                didOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then()
            axios.get(
                "/website-admin/websites",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + user.token
                    }
                }
            ).then((res) => {
                setWebsites(res.data)
                setLoaded(true)
                websiteAdminSwal.close()
            })

            setInit(false)
        }
    }, [init, user.isLoggedIn])

    const setResource = (resourceIndex, newResource) => {
        setWebsites(websites.map((website, websiteIndex) => {
            if (websiteIndex === currentWebsiteIndex) {
                website.collections.map((collection, collectionIndex) => {
                    if (collectionIndex === currentCollectionIndex) {
                        collection.resources.map((oldResource, index) => {
                            if (index === resourceIndex) {
                                return newResource
                            }
                            return oldResource
                        })
                    }
                    return collection
                })
            }
            return website
        }))
    }

    return (
        <WebsiteAdminContext.Provider value={{ loaded, currentWebsiteIndex, setCurrentWebsiteIndex, currentCollectionIndex, setCurrentCollectionIndex, websites, setResource }}>
            { children }
        </WebsiteAdminContext.Provider>
    )
}

export default WebsiteAdminContext