import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../../../context/UserContext";
import {useTranslation} from "react-i18next";
import BlogWriterContext from "../../../../context/BlogWriterContext";
import {useParams} from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function TextBlock({contentType, deleteContentType, moveContentType, isFirst, isLast}) {
    const { t, i18n } = useTranslation()
    const { user } = useContext(UserContext)
    const { blogPosts } = useContext(BlogWriterContext)
    const { blogPostId } = useParams()

    return (
        <div className="col-md-12">
            <div className="block block-rounded mb-3">
                <div className="block-header solidBorderHeader block-header-default block-header-thin">
                    <div className="block-options col-12 row mt-2">
                        <div className="col-6 p-0">
                            <h3 className="block-title">Text</h3>
                        </div>
                        <div className="col-6 p-0" style={{"textAlign": "right"}}>
                            { !isFirst &&
                                <button type="button" className="btn-block-option" onClick={() => {
                                    moveContentType(contentType.order, "up")
                                }}>
                                    <i className="far fa-fw fa-arrow-alt-circle-up"></i>
                                </button>
                            }
                            { !isLast &&
                                <button type="button" className="btn-block-option" onClick={() => {
                                    moveContentType(contentType.order, "down")
                                }}>
                                    <i className="far fa-fw fa-arrow-alt-circle-down"></i>
                                </button>
                            }
                            <button type="button" className="btn-block-option" onClick={() => {
                                deleteContentType(contentType.order)
                            }}>
                                <i className="far fa-fw fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="block-content m-0 p-0">
                    <CKEditor
                        editor={ ClassicEditor }
                        data={ contentType.content }
                        onChange={ ( event, editor ) => {
                            contentType.content = editor.getData()
                        } }
                        config={{
                            placeholder: t('TextBlock.typeSomething...'),
                            toolbar: {
                                items: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'link',
                                    'bulletedList',
                                    'numberedList',
                                    '|',
                                    'undo',
                                    'redo'
                                ]
                            },
                            language: 'de'
                        }}
                    />
                </div>
            </div>
        </div>
    )
}