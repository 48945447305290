import {useContext} from "react";
import {useTranslation} from "react-i18next";
import BlogWriterContext from "../../../context/BlogWriterContext";
import {setCurrentBlogIndexSession} from "../../../../SessionManager";

export default function BlogWriterHeader() {
    const { blogs, currentBlogIndex, getBlogsWithPosts } = useContext(BlogWriterContext)
    const { t } = useTranslation()

    const selectedBlogStyle = {cursor: "pointer", borderStyle: "solid", borderWidth: "1px", borderColor:"#9b9b9b"}
    return(
        <>
            <div className="content content-full">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
                    <h1 className="h3 fw-bold mb-1 mt-1">
                        BlogWriter
                    </h1>
                    <nav className="d-lg-flex align-items-center">
                        <div className="dropdown">
                            <button className="btn btn-sm btn-alt-primary mb-1 mt-1 m-lg-1" type="button"
                                    data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-feather opacity-50 me-1"></i>
                                <span>{ blogs[currentBlogIndex]?.name ?? t('BlogWriterHeader.selectBlog') }</span>
                                <i className="fa fa-fw fa-angle-down opacity-50"></i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-xxl dropdown-menu-mega p-0 border-0" id="dropdown-blog">
                                <div className="px-3 py-3 bg-primary rounded-top d-flex align-items-center justify-content-between">
                                    <h3 className="h5 fw-semibold text-white mb-0">
                                        { t("BlogWriterHeader.yourBlogs") }
                                    </h3>
                                    <i className="fa fa-2x fa-feather text-white opacity-25 ms-2"></i>
                                </div>
                                <div className="p-3">

                                    <div className="row g-3 fs-sm">
                                        {
                                            blogs.map((blog, index) => {
                                                return (
                                                    <div className="col-xxl-4" key={index}>
                                                        <a className={"block block-transparent block-rounded block-bordered block-link-pop mb-0 block" } style={ (currentBlogIndex === index) ? selectedBlogStyle : {cursor:"pointer"} }
                                                           onClick={ () => {
                                                               // quick fix: reload page
                                                               setCurrentBlogIndexSession(index)
                                                               window.location.reload();
                                                           } }>
                                                            <div className="block-content text-center ribbon">
                                                                { currentBlogIndex === index &&
                                                                    <div className="ribbon-box" style={{marginTop: "-10px", color:"green"}}>
                                                                        <i className="fa fa-fw fa-circle-check"></i>
                                                                    </div>
                                                                }
                                                                <i className="fa fa-2x fa-feather opacity-50"></i>
                                                                <p className="fw-medium mt-3">
                                                                    { blog.name }
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="col-xxl-4">
                                            <button className="block block-transparent block-rounded block-bordered block-link-pop mb-0" disabled>
                                                <div className="block-content text-center">
                                                    <i className="fa fa-2x fa-plus text-success opacity-50"></i>
                                                    <p className="fw-medium mt-3">
                                                        { t('BlogWriterHeader.newBlog') + " (coming soon)"}
                                                    </p>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div style={{ width: "96%", borderTopStyle: "solid", borderColor: "rgba(68,78,96,0.58)", borderWidth: "1px", marginLeft: "2%" }}></div>
        </>
    )
}