import React, {useContext} from "react";
import Login from "../Login";
import {Navigate} from "react-router-dom";
import BlogWriterHeader from "./modules/blogWriter/BlogWriterHeader";
import UserContext from "../context/UserContext";

export default function Start() {
    const { loaded } = useContext(UserContext)

    return (
        <>
            <Login />
        </>
    )
}