import React, {useContext, useState} from 'react';
import UserContext from "./context/UserContext";
import { useTranslation } from 'react-i18next';
import {Link, Navigate} from "react-router-dom";

function Login(props) {
    const { t } = useTranslation()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const { user, login } = useContext(UserContext)

    return ( 
        <>
            { user.isLoggedIn && (
                <Navigate to="/dashboard" />
            )}
            <div className="row justify-content-center push">
                <div className="col-md-8 col-lg-8">
                    <div className="block block-rounded mb-0">
                        <div className="block-header block-header-default">
                            <h3 className="block-title">{ t('Login.login') }</h3>
                            <div className="block-options">
                                <Link className="btn-block-option fs-sm" to="/reset-password">{ t('Login.forgotPassword?') }</Link>
                            </div>
                        </div>
                        <div className="block-content">
                            <div className="p-sm-3 px-lg-4 px-xxl-5 py-lg-5">
                                <h1 className="h2 mb-1">AllInOneAdmin</h1>
                                <p className="fw-medium text-muted">
                                    { t('Login.slogan') }
                                </p>
                                <div className="js-validation-signin">
                                    <div className="py-3">
                                        <div className="mb-4">
                                            <input type="text" className="form-control form-control-alt form-control-lg" id="login-email"
                                                   name="email"
                                                   placeholder={ t('Login.email') }
                                                   onChange={(e) => {
                                                       setEmail(e.target.value)
                                                   }}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input type="password" className="form-control form-control-alt form-control-lg" id="login-password"
                                                   name="password"
                                                   placeholder={ t('Login.password') }
                                                   onChange={(e) => {
                                                       setPassword(e.target.value)
                                                   }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6 col-xl-5">
                                            <button type="submit" className="btn w-100 btn-alt-primary" onClick={() => {
                                                    login(email, password).then()
                                                }}>
                                                <i className="fa fa-fw fa-sign-in-alt me-1 opacity-50"/> { t('Login.login') }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
     );
}

export default Login;