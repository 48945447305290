export default class Config {
    static getValue(key) {
        return Config.getEnvironmentConfig()[key] ?? ""
    }

    static getEnvironment() {
        return process.env.NODE_ENV
    }

    static getEnvironmentConfig() {
        if (Config.getEnvironment() === "production") {
            return require("./production/config.json")
        } else {
            return require("./development/config.json")
        }
    }
}