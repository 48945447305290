import {Link, matchPath, useLocation} from "react-router-dom";
import React from "react";

export default function NavItem({siIcon, linkName, to, matchPattern}) {
    const location = useLocation()
    return (
        <li className="nav-main-item">
            <Link className={ matchPath({path: to}, location.pathname) ? "nav-main-link active" : "nav-main-link"} to={to}>
                <i className={"nav-main-link-icon si " + siIcon}/>
                <span className="nav-main-link-name">{ linkName }</span>
            </Link>
        </li>
    )
}