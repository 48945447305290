import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

export default function Image({contentType, currentBlogPost, setCurrentBlogPost, currentContentTypeIndex, deleteContentType, moveContentType, isFirst, isLast}) {
    const { t } = useTranslation()

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div className="col-md-12">
            <div className="block block-rounded solidBorder mb-3">
                <div className="block-header block-header-default block-header-thin">
                    <div className="block-options col-12 row mt-2">
                        <div className="col-6 p-0">
                            <h3 className="block-title">{ t('Image.image') }</h3>
                        </div>
                        <div className="col-6 p-0" style={{"textAlign": "right"}}>
                            { !isFirst &&
                                <button type="button" className="btn-block-option" onClick={() => {
                                    moveContentType(contentType.order, "up")
                                }}>
                                    <i className="far fa-fw fa-arrow-alt-circle-up"></i>
                                </button>
                            }
                            { !isLast &&
                                <button type="button" className="btn-block-option" onClick={() => {
                                    moveContentType(contentType.order, "down")
                                }}>
                                    <i className="far fa-fw fa-arrow-alt-circle-down"></i>
                                </button>
                            }
                            <button type="button" className="btn-block-option" onClick={() => {
                                deleteContentType(contentType.order)
                            }}>
                                <i className="far fa-fw fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="block-content pt-2">
                    <div className="row">
                        <div className="col-12">
                            { contentType.content === "" &&
                                <div className="mt-2 mb-3">
                                    <input className="form-control" type="file" accept="image/*" onChange={async (e) => {
                                        const file = e.target.files[0]
                                        await convertBase64(file).then((base64Content) => {
                                            setCurrentBlogPost({
                                                ...currentBlogPost,
                                                contentTypes: [
                                                    ...currentBlogPost.contentTypes.filter((contentType, index) => {
                                                        return index !== currentContentTypeIndex
                                                    }),
                                                    ...currentBlogPost.contentTypes.filter((contentType, index) => { return index === currentContentTypeIndex }).map((contentType) => {
                                                        return {
                                                            ...contentType,
                                                            content: base64Content
                                                        }
                                                    })
                                                ]
                                            })
                                        })
                                    }} />
                                </div>
                            }
                            { contentType.content !== "" &&
                                <>
                                    <div className="mb-2" style={{textAlign: "center"}}>
                                        <img src={ contentType.content } style={{maxHeight: "300px", maxWidth: "100%"}}/>
                                    </div>
                                    <div className="input-group mt-2 mb-3">
                                        <span className="input-group-text">{ t('Image.imageName') }</span>
                                        <div className="form-floating">
                                            <input type="text" className="form-control" id="example-group1-floating1"
                                                   name="example-group1-floating1" placeholder={ t('Image.imageName') } disabled />
                                                <label htmlFor="example-group1-floating1">{ t('Image.imageName') } (coming soon...)</label>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}