import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

export default function TitleImage({setCurrentBlogPost, currentBlogPost}) {
    const { t } = useTranslation()

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result);
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        })
    }

    return (
        <div className="col-md-12">
            <div className="block block-rounded solidBorder mb-3">
                <div className="block-header block-header-default block-header-thin">
                    <div className="block-options col-12 row mt-2">
                        <div className="col-6 p-0">
                            <h3 className="block-title">{ t('TitleImage.titleImage') }</h3>
                        </div>
                        <div className="col-6 p-0" style={{"textAlign": "right"}}>
                            <button type="button" className="btn-block-option" onClick={() => {
                                setCurrentBlogPost({
                                    ...currentBlogPost,
                                    titleImage: ""
                                })
                            }}>
                                <i className="far fa-fw fa-trash-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="block-content pt-2">
                    <div className="row">
                        <div className="col-12">
                            { currentBlogPost.titleImage === "" &&
                                <div className="mt-2 mb-3">
                                    <input className="form-control" type="file" accept="image/*" onChange={async (e) => {
                                        const file = e.target.files[0]
                                        await convertBase64(file).then((base64Content) => {
                                            setCurrentBlogPost({
                                                ...currentBlogPost,
                                                titleImage: base64Content
                                            })
                                        })
                                    }} />
                                </div>
                            }
                            { currentBlogPost.titleImage !== "" &&
                                <>
                                    <div className="mb-2" style={{textAlign: "center"}}>
                                        <img src={ currentBlogPost.titleImage } style={{maxHeight: "300px", maxWidth: "100%"}}/>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}