import {useContext} from "react";
import {useTranslation} from "react-i18next";
import BlogWriterContext from "../../../context/BlogWriterContext";
import {Link} from "react-router-dom";

export default function EditBlogPostHeader() {
    const {  } = useContext(BlogWriterContext)
    const { t } = useTranslation()

    const selectedWebsiteStyle = {cursor: "pointer", borderStyle: "solid", borderWidth: "1px", borderColor:"#9b9b9b"}
    return(
        <>
            <div className="content content-full">
                <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center py-2">
                    <a href="/module/4/BlogWriter" type="button" className="btn btn-sm btn-secondary">
                        <i className="fa fa-fw fa-backward me-1"></i> { t('EditBlogPostHeader.back') }
                    </a>
                </div>
            </div>
            <div style={{ width: "96%", borderTopStyle: "solid", borderColor: "rgba(68,78,96,0.58)", borderWidth: "1px", marginLeft: "2%" }}></div>
        </>
    )
}