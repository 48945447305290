export function getUserTokenSession() {
    return sessionStorage.getItem('userToken');
}

export function setUserTokenSession(userToken) {
    if (userToken === null) {
        sessionStorage.removeItem('userToken');
    }
    sessionStorage.setItem('userToken', userToken);
}

export function getCurrentBlogIndexSession() {
    return parseInt(sessionStorage.getItem('currentBlogIndex'));
}

export function setCurrentBlogIndexSession(currentBlogIndex) {
    sessionStorage.setItem('currentBlogIndex', currentBlogIndex);
}