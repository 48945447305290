import React, {useContext} from "react";
import UserContext from "./../context/UserContext";
import {Link} from "react-router-dom";

export default function Dashboard() {
    const { user } = useContext(UserContext)
    return (
        <>
            { user.isLoggedIn &&
                <div className="block block-rounded">
                    <div className="bg-image">
                        <div className="bg-black-50">
                            <div className="content content-full text-center">
                                <div className="my-3">
                                    <img className="img-avatar img-avatar-thumb" src="/assets/media/avatars/avatar13.jpg" alt="" />
                                </div>
                                <h1 className="h2 text-white mb-0">{ user.firstName + " " + user.lastName }</h1>
                            </div>
                        </div>
                    </div>
                    <div className="content content-boxed">
                        <div className="row">
                            {
                                user.modules.map((module, index) => {
                                    return <div className="col-sm-6 col-md-3" key={index}>
                                        <Link className="block block-bordered block-rounded block-link-shadow" to={`/module/${module.id}/${module.name}`}>
                                            <div className="block-content block-content-full border-bottom text-center">
                                                <div className="py-3">
                                                    <i className="si si-puzzle fa-2x text-amethyst"></i>
                                                </div>
                                            </div>
                                            <div
                                                className="block-content block-content-full d-flex align-items-center justify-content-between">
                                                <span className="fw-semibold fs-md">
                                                    { module.name }
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}