import React, {createContext, useContext, useEffect, useState} from "react";
import UserContext from "./UserContext";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Config from "../../config/Config";
import Swal from "sweetalert2";
import {getCurrentBlogIndexSession} from "../../SessionManager";

const BlogWriterContext = createContext()

export function BlogWriterProvider({ children }) {
    const { t } = useTranslation()
    const { user } = useContext(UserContext)
    const [init, setInit] = useState(true)
    const [loaded, setLoaded] = useState(false)

    const [currentBlogIndex, setCurrentBlogIndex] = useState(0)
    const [blogs, setBlogs] = useState([])
    const [currentBlogPosts, setCurrentBlogPosts] = useState([])

    const blogApiClient = axios.create({
        baseURL: Config.getValue("blogApi").url
    })

    useEffect(() => {
        if (init && user.isLoggedIn) {

            let sessionBlogIndex = 0;
            if (sessionStorage.getItem('currentBlogIndex') != null) {
                sessionBlogIndex = getCurrentBlogIndexSession()
            }
            getBlogsWithPosts(sessionBlogIndex)

            setInit(false)
        }
    }, [init, user.isLoggedIn])


    function getBlogsWithPosts(currentBlogIndex) {
        let blogWriterSwal = Swal
        blogWriterSwal.fire({
            title: t('BlogWriterContext.loadingBlogPosts'),
            didOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then()

        blogApiClient.get(
            "/users/" + user.id + "/blogs",
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                }
            }
        ).then((res) => {
            setBlogs(res.data)
            if (res.data.length > 0) {
                blogApiClient.get(
                    "/users/" + user.id + "/blogs/" + res.data[currentBlogIndex].id + "/blog-posts",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + user.token
                        }
                    }
                ).then((res) => {
                    setCurrentBlogPosts(res.data)
                    setLoaded(true)
                    blogWriterSwal.close()
                }).catch((err) => {

                })
                setCurrentBlogIndex(currentBlogIndex)
            }
        }).catch((err) => {

        })
    }

    return (
        <BlogWriterContext.Provider value={{ blogs, currentBlogPosts, setCurrentBlogPosts, currentBlogIndex, setCurrentBlogIndex, loaded, getBlogsWithPosts}}>
            { children }
        </BlogWriterContext.Provider>
    )
}

export default BlogWriterContext