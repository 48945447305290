import {Navigate} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from 'react';
import UserContext from "../../../context/UserContext";
import {useTranslation} from "react-i18next";
import BlogWriterContext from "../../../context/BlogWriterContext";
import {useParams} from "react-router-dom";
import EditBlogPostHeader from "./EditBlogPostHeader";
import TextBlock from "./inputTypes/TextBlock";
import Image from "./inputTypes/Image";
import Swal from "sweetalert2";
import axios from "axios";
import Config from "../../../../config/Config";
import TitleImage from "./inputTypes/TitleImage";

export default function EditBlogPost() {
    const { t, i18n } = useTranslation()
    const { user } = useContext(UserContext)
    const [init, setInit] = useState(true)
    const [blogPostLoaded, setBlogPostLoaded] = useState(false)
    const { currentBlogPosts, loaded, blogs, currentBlogIndex } = useContext(BlogWriterContext)
    const { blogPostId } = useParams()

    const [currentBlogPost, setCurrentBlogPost] = useState({})

    const dateOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
    }

    const blogApiClient = axios.create({
        baseURL: Config.getValue("blogApi").url
    })

    useEffect(() => {
        if (init && loaded && user.isLoggedIn) {
            let blogWriterSwal = Swal
            blogWriterSwal.fire({
                title: t('EditBlogPost.loadingBlogPost'),
                didOpen: () => {
                    Swal.showLoading()
                },
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then()

            blogApiClient.get(
                "/blogs/" + blogs[currentBlogIndex].id + "/blog-posts/" + blogPostId,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + user.token
                    }
                }
            ).then((res) => {
                setCurrentBlogPost(res.data)
                setBlogPostLoaded(true)
                blogWriterSwal.close()
            }).catch((err) => {

            })

            setInit(false)
        }
    }, [init, loaded, user.isLoggedIn])

    const publishBlogPost = async function (publish) {
        if (publish) {
            currentBlogPost.published = true
            currentBlogPost.publishedAt = new Date()
        } else {
            currentBlogPost.published = false
            currentBlogPost.publishedAt = null
        }

        await blogApiClient.post(
            "/blog-posts", {
                ...currentBlogPost
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + user.token
                }
            }
        ).catch(err => {
            console.log(err)
        })

        await Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: t('EditBlogPost.savedPost'),
            showConfirmButton: false,
            timer: 1000
        }).then()
    }

    const addContentType = function (contentTypeType) {
        let orderArray = currentBlogPost.contentTypes.map((contentType) => contentType.order).length !== 0
            ? currentBlogPost.contentTypes.map((contentType) => contentType.order)
            : [-1]

        if (["Text", "Image"].includes(contentTypeType)) {
            setCurrentBlogPost({
                ...currentBlogPost,
                contentTypes: [
                    ...currentBlogPost.contentTypes,
                    {
                        "id": 0,
                        "type": contentTypeType,
                        "content": "",
                        "order": Math.max(...orderArray) + 1
                    }
                ]
            })
        }
    }

    const deleteContentType = function (contentTypeOrder) {
        setCurrentBlogPost({
            ...currentBlogPost,
            contentTypes: currentBlogPost.contentTypes.filter(contentType =>
                contentType.order !== contentTypeOrder
            )
        })
    }

    const moveContentType = function (contentTypeOrder, direction) {
        let afterContentTypeOrder = contentTypeOrder
        if (direction === "up") {
            afterContentTypeOrder = afterContentTypeOrder - 1
            if (afterContentTypeOrder < 0) {
                afterContentTypeOrder = 0
            }
        } else {
            afterContentTypeOrder = afterContentTypeOrder + 1
            if (afterContentTypeOrder > currentBlogPost.contentTypes.length-1) {
                afterContentTypeOrder = currentBlogPost.contentTypes.length-1
            }
        }

        if (afterContentTypeOrder !== contentTypeOrder) {
            let contentTypes
            if (currentBlogPost.contentTypes.length > 2) {
                contentTypes = [
                    { ...currentBlogPost.contentTypes.find(contentType => contentType.order === contentTypeOrder), order: afterContentTypeOrder },
                    { ...currentBlogPost.contentTypes.find(contentType => contentType.order === afterContentTypeOrder), order: contentTypeOrder },
                    ...currentBlogPost.contentTypes.filter(contentType => {
                        return contentType.order !== contentTypeOrder && contentType.order !== afterContentTypeOrder
                    })
                ]
            } else {
                contentTypes = [
                    { ...currentBlogPost.contentTypes.find(contentType => contentType.order === contentTypeOrder), order: afterContentTypeOrder },
                    { ...currentBlogPost.contentTypes.find(contentType => contentType.order === afterContentTypeOrder), order: contentTypeOrder }
                ]
            }
            setCurrentBlogPost({
                ...currentBlogPost,
                contentTypes: contentTypes
            })
        }
    }

    return (
        <>
            { user.isLoggedIn === false && (
                <Navigate to="/" />
            )}
            <div className="bg-body-light" style={{minHeight:"1000px"}}>
                <EditBlogPostHeader />

                { blogPostLoaded &&
                    <div className="content">
                        <div className="block block-rounded">
                            <div className="block-content row pb-2">
                                <div className="col-lg-8" style={{"textAlign": "center"}}>
                                    <button type="button"
                                            className="btn btn-lg rounded-pill btn-success px-4 mb-1 me-2" onClick={() => {
                                        publishBlogPost(true)
                                    }}>
                                        <i className="si si-rocket me-1"></i> { t('EditBlogPost.launchBlogPost') }
                                    </button>
                                    <button type="button" className="btn btn-lg btn-alt-warning mb-1 me-2" onClick={() => {
                                        publishBlogPost(false)
                                    }}>
                                        <i className="fa fa-save me-1"></i> { t('EditBlogPost.saveAsDraft') }
                                    </button>
                                </div>
                                <div className="col-lg-4 mb-1" style={{"textAlign": "right"}}>
                                    { t('EditBlogPost.publishedAt') }: { currentBlogPost.publishedAt !== null ? new Date(currentBlogPost.publishedAt).toLocaleString(i18n.language, dateOptions) : t('EditBlogPost.notYet') }<br/>
                                    { t('EditBlogPost.createdAt') }: { new Date(currentBlogPost.createdAt).toLocaleString(i18n.language, dateOptions) }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <TitleImage currentBlogPost={currentBlogPost} setCurrentBlogPost={setCurrentBlogPost} />
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input type="text" className="form-control"
                                                   name="example-text-input-floating" placeholder="..." value={ currentBlogPost.title } autoComplete="off"
                                                   onChange={(e) => {
                                                       setCurrentBlogPost({
                                                           ...currentBlogPost,
                                                           title: e.target.value
                                                       })
                                                   }}
                                            />
                                            <label htmlFor="example-text-input-floating">{ t('EditBlogPost.title') }</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-floating mb-4">
                                            <input type="text" className="form-control"
                                                   name="example-text-input-floating" placeholder="..." value={ currentBlogPost.subtitle } autoComplete="off"
                                                   onChange={(e) => {
                                                       setCurrentBlogPost({
                                                           ...currentBlogPost,
                                                           subtitle: e.target.value
                                                       })
                                                   }}
                                            />
                                            <label htmlFor="example-text-input-floating">{ t('EditBlogPost.subtitle') }</label>
                                        </div>
                                    </div>
                                </div>
                                {
                                    currentBlogPost.contentTypes.sort((a, b) => {
                                        return a.order - b.order;
                                    }).map((contentType, index) => {
                                        return <div key={index}>
                                            { contentType.type === "Text" &&
                                                <TextBlock contentType={contentType} deleteContentType={deleteContentType} moveContentType={moveContentType} isFirst={index===0} isLast={index===currentBlogPost.contentTypes.length-1}/>
                                            }
                                            { contentType.type === "Image" &&
                                                <Image contentType={contentType} currentBlogPost={currentBlogPost} setCurrentBlogPost={setCurrentBlogPost} currentContentTypeIndex={index} deleteContentType={deleteContentType} moveContentType={moveContentType} isFirst={index===0} isLast={index===currentBlogPost.contentTypes.length-1}/>
                                            }
                                        </div>
                                    })
                                }
                            </div>
                            <div className="col-12 mb-2" style={{"textAlign": "center"}}>
                                <button type="button" className="btn btn-lg btn-primary me-2 mb-2" onClick={() => {
                                    addContentType("Text")
                                }}>
                                    <i className="fa fa-pen me-1"></i> { t('EditBlogPost.insertTextblock') }
                                </button>
                                <button type="button" className="btn btn-lg btn-primary me-2 mb-2" onClick={() => {
                                    addContentType("Image")
                                }}>
                                    <i className="fa fa-image me-1"></i> { t('EditBlogPost.insertImage') }
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}