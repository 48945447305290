export function createUser(
    id, firstName, lastName, email, isLoggedIn, token, modules
) {
    return {
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        isLoggedIn: isLoggedIn,
        token: token,
        modules: modules
    }
}

export function createEmptyUser() {
    return {
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        isLoggedIn: false,
        token: null,
        modules: []
    }
}