import React, {createContext, useEffect, useState} from "react";
import {getUserTokenSession, setUserTokenSession} from "../../SessionManager";
import axios from "../../api/axios";
import {createEmptyUser, createUser} from "../../domain/UserFactory";
import Swal from "sweetalert2";
import {useTranslation} from "react-i18next";
import {AxiosError} from "axios";

const UserContext = createContext()

export function AppProvider({ children }) {
    const LOGIN_URL = "/auth"
    const [init, setInit] = useState(true)
    const [user, setUser] = useState({})
    const { t } = useTranslation()

    useEffect(() => {
        let token = getUserTokenSession()
        if (init && (token !== null && token !== 'null')) {
            login(null, null, token).then()
            setInit(false)
        }
    }, [init])

    const login = async (email = null, password = null, token = null) => {
        let showSuccessModal = true
        let logginInSwal = Swal
        logginInSwal.fire({
            title: t('UserContext.loggingIn'),
            didOpen: () => {
                Swal.showLoading()
            },
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then()

        try {
            let res
            if (token !== null) {
                showSuccessModal = false
                res = await axios.get(
                    LOGIN_URL,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + token
                        }
                    }
                )
            } else {
                res = await axios.post(
                    LOGIN_URL,
                    JSON.stringify({
                        email,
                        password
                    }),
                    {
                        headers: { "Content-Type": "application/json" }
                    }
                )
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: t('UserContext.loginSuccess'),
                    showConfirmButton: false,
                    timer: 1000
                }).then()
            }

            setUser(createUser(
                res.data.id,
                res.data.firstName,
                res.data.lastName,
                res.data.email,
                true,
                res.data.token,
                res.data.modules
            ))
            setUserTokenSession(res.data.token)
            !showSuccessModal && logginInSwal.close()
            return true
        } catch (err) {
            logginInSwal.close()
            if (err.code === AxiosError.ERR_BAD_REQUEST) {
                Swal.fire({
                    title: t('UserContext.wrong-email/password'),
                    icon: "error"
                }).then()
            } else {
                Swal.fire({
                    title: t('UserContext.unknownError'),
                    icon: "error"
                }).then()
            }
            console.clear()
            return false
        }
    }

    const logout = () => {
        setUser(createEmptyUser())
        setUserTokenSession(null)
    }

    return (
        <UserContext.Provider value={{user, login, logout}}>
            { children }
        </UserContext.Provider>
    )
}

export default UserContext