import React from "react";
import {useState} from "react";

export default function Dropdown({siIcon, linkName, children}) {
    const [subMenuToggle, setSubMenuToggle] = useState('')

    const toggleSubMenu = () => {
        if (window.innerWidth <= 991) {
            setSubMenuToggle(subMenuToggle === 'open' ? '' : 'open')
        }
    }

    return(
        <li className={"nav-main-item " + subMenuToggle}>
            <div className="nav-main-link nav-main-link-submenu" style={{cursor: 'pointer'}} onClick={() => {
                toggleSubMenu()
            }}>
                <i className={"nav-main-link-icon si " + siIcon}/>
                <span className="nav-main-link-name">
                  { linkName }
                </span>
            </div>
            <ul className="nav-main-submenu">
                { children }
            </ul>
        </li>
    )
}