import React, {useContext} from "react";
import UserContext from "./context/UserContext";
import {changeLanguage} from "i18next";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";

function Header() {
  const { user, logout } = useContext(UserContext)
  const { t, i18n } = useTranslation()
  const supportedLanguages = ['de', 'en']
  const navigate = useNavigate()

  return (
    <header id="page-header">
      <div className="content-header main-content-boxed-custom">
        <div className="d-flex align-items-center">
          <Link className="fw-semibold fs-5 tracking-wider text-dual me-3" to="/">
            AllInOneAdmin
          </Link>
        </div>

        <div className="d-flex align-items-center">
          { !user.isLoggedIn &&
                <Link to="/login" type="button" className="btn btn-md btn-alt-secondary">
                  <span className="d-none d-sm-inline-block ms-1">{ t('Header.startNow') }&nbsp;</span>
                  <i className="fa fa-fw fa-rocket"/>
                </Link>
          }
          { user.isLoggedIn &&
              <div className="dropdown d-inline-block ms-2">
                <button type="button" className="btn btn-md btn-alt-secondary" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img className="rounded-circle" src="/assets/media/avatars/avatar10.jpg" alt="Header Avatar" style={{width: "21px"}} />
                  <span className="d-none d-sm-inline-block ms-1">{ user.firstName }</span>
                  <i className="fa fa-fw fa-angle-down d-none d-sm-inline-block"/>
                </button>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-end p-0 border-0" aria-labelledby="page-header-user-dropdown">
                  <div className="p-3 text-center bg-body-light border-bottom rounded-top">
                    <img className="img-avatar img-avatar48 img-avatar-thumb" src="/assets/media/avatars/avatar10.jpg" alt="" />
                    <p className="mt-2 mb-0 fw-medium">{ user.firstName + " " + user.lastName }</p>
                  </div>
                  <div className="p-2">
                    { user.isLoggedIn &&
                        <div className="dropdown-item d-flex align-items-center justify-content-between" style={{cursor: 'pointer'}}
                             onClick={() =>{
                                logout()
                                navigate("/")
                             }}>
                          <span className="fs-sm fw-medium">{ t('Header.logout') }</span>
                        </div>
                    }
                  </div>
                </div>
              </div>
          }

          <div className="dropdown d-inline-block ms-2">
            <button type="button" className="btn btn-md btn-alt-secondary" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              { i18n.language.toUpperCase() + ' ' }
              <i className="fa fa-fw fa-angle-down d-none d-sm-inline-block"/>
            </button>
            <div className="dropdown-menu p-0 border-0" aria-labelledby="page-header-user-dropdown" style={{minWidth: '0px'}}>
              <div className="p-2">
                {
                  supportedLanguages.map((lang) => {
                    if (lang !== i18n.language) {
                      return (
                        <div key={ lang } className="dropdown-item d-flex align-items-center justify-content-between" style={{cursor: 'pointer'}}
                             onClick={() => {
                               changeLanguage(lang)
                             }}>
                          <span className="fs-sm fw-medium">
                            <b>{ lang.toUpperCase() }</b>
                          </span>
                        </div>
                      )
                    }
                    return("")
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="page-header-loader" className="overlay-header bg-primary-lighter">
        <div className="content-header">
          <div className="w-100 text-center">
            <i className="fa fa-fw fa-circle-notch fa-spin text-primary"/>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header