import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import UserContext from "./context/UserContext";
import {Link, matchPath, useLocation} from "react-router-dom";
import NavItem from "./navigation/NavItem";
import Dropdown from "./navigation/Dropdown";

function Navigation() {
    const { user } = useContext(UserContext)
    const { t, i18n } = useTranslation()
    const [menuToggle, setMenuToggle] = useState('d-none')
    const [routes, setRoutes] = useState([])
    const location = useLocation()

    const toggleMenu = () => {
        setMenuToggle(menuToggle === 'd-none' ? '' : 'd-none')
    }

    function getModuleDropdownName() {
        return matchPath({path: "/module/:moduleId/*"}, location.pathname) ?
            user.modules.find(({id}) =>
                id === parseInt(location.pathname.split("/")[2])
            ).name : t('Navigation.your_modules')
    }

    function getModuleDropdownChildren() {
        if (user.modules?.length == null) {
            return [
                {name: t('Navigation.addModules'), url: "/modules", matchPattern: "/modules"},
            ]
        }

        let children = []
        user.modules?.map((module) => {
            let uri = `/module/${module.id}/${module.name}`
            children.push(
                {name: module.name, url: uri}
            )
        })
        return children
    }

    useEffect(() => {
        setRoutes(
            user.isLoggedIn ?
                [ // private routes
                    {icon: "si-speedometer", name: t('Navigation.dashboard'), url: "/dashboard", matchPattern: "/dashboard", isDropdown: false},
                    {icon: "si-puzzle", name: getModuleDropdownName(), isDropdown: true, children: getModuleDropdownChildren()}
                ] : [ // public routes
                    {icon: "si-home", name: t('Navigation.home'), url: "/", matchPattern: "/", isDropdown: false}
                ]
        )
    }, [user.isLoggedIn, location, i18n.language])

  return(
    <div className="bg-primary-darker">
      <div className="content py-3 main-content-boxed-custom">
        <div className="d-lg-none">
          <button type="button" className="btn w-100 btn-alt-secondary d-flex justify-content-between align-items-center" onClick={() => {
            toggleMenu()
          }}>
            { t('Navigation.menu') }
            <i className="fa fa-bars"/>
          </button>
        </div>

        <div id="main-navigation" className={"d-lg-block mt-2 mt-lg-0 " + menuToggle}>
          <ul className="nav-main nav-main-dark nav-main-horizontal nav-main-hover">
              {
                  routes.map((route, index) => {
                      if (route.isDropdown) {
                          return (
                            <Dropdown key={index} siIcon={route.icon} linkName={route.name}>
                                {
                                    route.children.map((subRoute, subIndex) => {
                                        return (
                                            <li key={ subIndex } className="nav-main-item">
                                                <Link className={ matchPath({path: subRoute.url}, location.pathname) ? "nav-main-link active" : "nav-main-link"} to={subRoute.url}>
                                                    <span className="nav-main-link-name">{ subRoute.name }</span>
                                                </Link>
                                            </li>
                                        )
                                    })
                                }
                            </Dropdown>
                          )
                      } else {
                          return (
                              <NavItem key={index} siIcon={route.icon} linkName={route.name} to={route.url} />
                          )
                      }
                  })
              }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navigation