import React, {useContext} from "react";
import Login from './components/Login';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Navigation from './components/Navigation';
import Header from './components/Header';
import Start from "./components/pages/Start";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {WebsiteAdminProvider} from "./components/context/WebsiteAdminContext";
import UserContext from "./components/context/UserContext";
import {BlogWriterProvider} from "./components/context/BlogWriterContext";
import EditBlogPost from "./components/pages/modules/blogWriter/EditBlogPost";
import Dashboard from "./components/pages/Dashboard";

function App() {
    const { user } = useContext(UserContext)
    const ProductionTracing = React.lazy(() => import("./components/pages/modules/productionTracing/ProductionTracing.js"));
    const InvoiceManager = React.lazy(() => import("./components/pages/modules/invoiceManager/InvoiceManager.js"));
    const WebsiteAdmin = React.lazy(() => import("./components/pages/modules/websiteAdmin/WebsiteAdmin.js"));
    const BlogWriter = React.lazy(() => import("./components/pages/modules/blogWriter/BlogWriter.js"));

    const placeholder = () => {
        return (
            <SkeletonTheme baseColor="#cacdd1" highlightColor="#d9dce0">
                <h1><Skeleton /></h1>
                <Skeleton count={10} height={20} /><br/>
                <h1><Skeleton /></h1>
                <Skeleton count={8} height={20} />
            </SkeletonTheme>
        )
    }

    return (
        <main id="main-container">
        <BrowserRouter>
            <Header />
            <Navigation />
            <div style={{minHeight: "83.95vh"}} className="content py-3 main-content-boxed-custom">
                <Routes>
                    <Route path="/" element={<Start />} />
                    <Route path="/index.html" element={<Start />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    { user.isLoggedIn &&
                        <>
                            <Route path="/module/1/:moduleName" element={
                                <React.Suspense fallback={placeholder()}>
                                    <ProductionTracing />
                                </React.Suspense>
                            } />
                            <Route path="/module/2/:moduleName" element={
                                <React.Suspense fallback={placeholder()}>
                                    <InvoiceManager />
                                </React.Suspense>
                            } />
                            <Route path="/module/3/:moduleName" element={
                                <React.Suspense fallback={placeholder()}>
                                    <WebsiteAdminProvider>
                                        <WebsiteAdmin />
                                    </WebsiteAdminProvider>
                                </React.Suspense>
                            } />
                            <Route path="/module/4/:moduleName" element={
                                <React.Suspense fallback={placeholder()}>
                                    <BlogWriterProvider>
                                        <BlogWriter />
                                    </BlogWriterProvider>
                                </React.Suspense>
                            } />
                            <Route path="/module/4/:moduleName/post/:blogPostId" element={
                                <React.Suspense fallback={placeholder()}>
                                    <BlogWriterProvider>
                                        <EditBlogPost />
                                    </BlogWriterProvider>
                                </React.Suspense>
                            } />
                        </>
                    }
                    <Route path="/*" element={<div>404</div>} />
                </Routes>
            </div>
        </BrowserRouter>
        </main>
    );
}

export default App;
